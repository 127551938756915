<template>
    <div class="dp-f jc-c">
        <div class="operationItem cu-p" :class="props.operationIndex == ind ? 'operationItemac' : ''"
            v-for="(el,ind) in props.operationData" :key="ind" @click="emit('choose', ind)">
            {{ el.title }}
        </div>
    </div>
</template>
<script setup>
import { defineEmits } from 'vue'
const props = defineProps({
    operationIndex: {
        //当前操作下标
        type: Number,
        default: () => {
            return 0
        }
    },
    operationData: {
        //当前操作下标
        type: Array,
        default: () => {
            return [
                { title: '密码登录' },
                { title: '验证码登录' }
            ]
        }
    },
});
const emit = defineEmits(['choose'])
</script>
<style lang="scss" scoped >
@import '@/styles/color/value.scss';

.operationItem {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
}

.operationItemac {
    font-weight: 600;
    color: $active-theme;
    padding-bottom: 12px;
    border-bottom: 2px solid $active-theme;
}

.operationItem+.operationItem {
    margin-left: 38px;
}
</style>