<template>
  <div class="registerbox">
    <headComponent class="m-0-a"></headComponent>
    <div class="register m-0-a mb-20">
      <div class="title ta-c">欢迎使用章易签</div>
      <div class="w-462 m-0-a">
        <el-form :model="formModel" class="demo_ruleForm" ref="ruleFormRef">
          <template v-if="state.operationIndex == 0">
            <el-form-item :rules="[{ required: true, message: '请输入创建人真实姓名' }]" prop="name">
              <el-input v-model="formModel.name" placeholder="创建人真实姓名" />
            </el-form-item>
          </template>
          <el-form-item :rules="[{ validator: validPhoneData, trigger: 'blur' }]" prop="phone">
            <el-input v-model="formModel.phone" placeholder="手机号" />
          </el-form-item>
          <el-form-item :rules="[{ validator: validPasswordData, trigger: 'blur' }]" prop="password">
            <el-input v-model="formModel.password" placeholder="8-16位数字,字母和特殊字符任意两种组合密码" show-password />
          </el-form-item>
          <el-form-item :rules="[{ validator: confirmPassword, trigger: 'blur' }]" prop="password2">
            <el-input v-model="formModel.password2" placeholder="请确认密码" show-password />
          </el-form-item>
 
          <el-form-item
            :rules="[{ required: true, message: '请输入验证码' }, { min: 6, max: 6, message: '验证码的长度为6', trigger: 'blur' }]"
            prop="code" class="pt-r">
            <verificationCode ref="verificationCodeRef" class="pt-a vcode" :CSStype="1" @Emit="vCodeEmit">
            </verificationCode>
            <el-input v-model="formModel.code" placeholder="验证码" maxlength="6" />
          </el-form-item>
        </el-form>
        <read class="mb-24" ref="readRef"></read>
        <oabutton class="mb-30" width='462' height='48' title="注册" CSStype=2
          style="border-radius: 4px;font-size: 18px;font-weight: 500;" @buttonclick="register()"
          v-model:loading="state.loading"></oabutton>
        <div class="ml-8 dp-f jc-c" style="margin-bottom: 62px;">
          <div class="mr-4">使用已有账号</div><span class="cu-p" style="color:rgba(36, 137, 242, 1);"
            @click="router_push_name('login')">直接登录</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, reactive, ref, unref } from 'vue'
import { validPhone, validEmail, validPassword } from "@/utils/server/validate.js";
import { router_push_name } from "@/utils/server/router.js";
import { handleMessage } from "@/utils/server/confirm.js";
import { httpToken } from "@/utils/request";
import qs from 'qs'
import headComponent from "./components/register/head.vue"
import choose from './components/choose.vue'//选择组件
import read from './components/read.vue'//阅读协议
import { encryption } from '@/utils/encryption/encryption.js'
import verificationCode from '@/components/button/verificationCode.vue'//验证码组件
const state = reactive({
  operationIndex: 0,//当前操作下标
  checkPhone: 0,//手机号是否重复 1:手机号可用 2:手机号重复
  loading: false,//loding显示
})
const ruleFormRef = ref()//表单ref
const readRef = ref()//阅读协议ref
const verificationCodeRef = ref()//验证码组件ref
// 注册
const register = (() => {
  // 是否阅读协议
  if (unref(readRef).state.isread == true) {
    submitForm()
  } else {
    unref(readRef).jitter()
    handleMessage('请先阅读并同意《使用协议》和《隐私协议》')
  }
})
// unref(readRef).read(1, '使用协议')
// 表单提交
const submitForm = async () => {
  state.loading = true
  const form = unref(ruleFormRef)
  if (!form) {
    state.loading = false
    return
  }
  await form.validate((valid) => {
    if (valid) {
      // 个人用户
      if (state.operationIndex == 1) {
        regIndividual()
      } else if (state.operationIndex == 0) {//企业用户
        regEnterprise()
      }
    } else {
      state.loading = false
    }
  })
}
// 注册企业用户
const regEnterprise = (() => {
  const user = encryption({
    data: { password: formModel.password },
    key: 'thanks,pig4cloud',
    param: ['password']
  })
  httpToken({
    method: "post",
    url: '/admin/register/company',
    data: {
      // 'companyName': formModel.companyName,
      'corporateName': formModel.name,
      'password': user.password,
      'phone': formModel.phone,
      'code': formModel.code,
    }
  }).then(res => {
    state.loading = false
    if (res.code == 200) {
      handleMessage('注册成功请直接登录', 'success')
      router_push_name('login')
    }
  }).catch(res => {
    state.loading = false
  })
})
// 注册个人用户
const regIndividual = (() => {
  httpToken({
    method: "post",
    url: '/admin/register/user',
    data: {
      'phone': formModel.phone,
      'password': formModel.password,
      'code': formModel.code,
      'username': formModel.phone,
    }
  }).then(res => {
    state.loading = false
    if (res.code == 200) {
      handleMessage('注册成功请直接登录', 'success')
      router_push_name('login')
    }
  }).catch(res => {
    state.loading = false
  })
})
// 验证码返回
const vCodeEmit = (() => {
  if (!validPhone(formModel.phone)) {
    handleMessage('请输入正确手机号')
    return
  }
  // 手机号可用情况发起验证码
  if (state.checkPhone == 2) {
    httpToken({
      method: "post",
      url: '/admin/app/sms',
      data: {
        'phone': formModel.phone,
        'exist': false,
        'appid': '1',
        'type': 2,
      }
    }).then(res => {
      unref(verificationCodeRef).send()
    })
  } else {
    handleMessage('手机号已注册')
  }
})
// 校验密码格式
const validPasswordData = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入密码'))
  } else {
    if (validPassword(value)) {
      callback()
    } else
      callback(new Error('密码格式错误(8-16位数字,字母和特殊字符任意两种组合密码)'))
  }
}
// 校验密码是否相同
const confirmPassword = (rule, value, callback) => {
  // console.log("password: ", formModel.password)
  // console.log("password2: ",  value)
  if (value === '') {
    callback(new Error('请再次输入密码'))
  } else {
    if (value == formModel.password) {
      callback()
    } else
      callback(new Error('输入的密码不一致，请重新输入'))
  }
}
// 校验手机号格式
const validPhoneData = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入手机号'))
  } else {
    if (validPhone(value)) {
      httpToken({
        method: "post",
        url: '/admin/app/checkPhone',
        data: qs.stringify({
          'phone': value,
          'type': 2
        })
      }).then(res => {
        state.checkPhone = res.data
        // res.data 1手机号已存在 2手机号不存在
        if (res.data == 2) {
          callback()
        } else {
          callback(new Error(res.msg))
        }
      }).catch(() => {
        callback(new Error('校验手机号接口请求失败'))
      })
    } else
      callback(new Error('手机号格式错误'))
  }
}
const formModel = reactive({
  phone: '',//手机号
  code: '',//验证码
  password: '',//密码
  password2: '', //确认密码
  name: '',//真实姓名
  companyName: '',//企业、政府或组织名称
})
const chooseChange = (ind) => {
  state.operationIndex = ind
}

onMounted(() => {
  console.log("onMounted start")
  unref(readRef).setAutoPop(true)
  unref(readRef).read(1, "使用协议")
  // readRef.read(1, '使用协议')
})
</script>
<style lang="scss" scoped >
@import "@/styles/general/element.scss"; //element 样式重置

.registerbox {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;

  .demo_ruleForm {
    ::v-deep .el-input {
      width: 460px;
      height: 54px;
      border: 1px solid #E8E8E8;

      .el-input__wrapper {
        box-shadow: none;
      }
    }
  }

  .register {
    margin-top: 90px;
    width: 600px;
    // min-height: 676px;
    background: rgba(239, 200, 110, 0.05);
    border-radius: 4px;
    border: 1px solid #EEC774;

    .title {
      margin-top: 58px;
      margin-bottom: 70px;
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333330;
    }
  }

}

::v-deep .el-input__wrapper {
  padding: 1px 15px;
}

.vcode {
  z-index: 10;
  right: 18px;
}</style>