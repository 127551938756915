<template>
  <div class="dp-f h-22 ai-c">
    <el-checkbox v-model="state.isread" :disabled="!state.readprivacy" @click="readClick"/>
    <div class="read ml-8" :class="state.jitter ? 'readac' : ''">
      我已阅读并同意
      <span :class="['cu-p', state.readIs1 ? 'readIsAc' : '']" @click="read(1, '使用协议')">《使用协议》</span>
      和
      <span :class="['cu-p', state.readIs2 ? 'readIsAc' : '']" @click="read(0, '隐私协议')">《隐私协议》</span>
    </div>
  </div>
  <agreement ref="agreementRef" @typeIndexEmit="agreementEmit"/>
</template>
<script setup>
import { reactive, ref,unref } from 'vue'
import { handleMessage } from "@/utils/server/confirm.js";
import agreement from '@/components/dialog/agreement.vue'
const agreementRef=ref()
// const autoPop = ref(false)
const state = reactive({
  autoPop: false,
  jitter: false,//抖动
  isread: false,//协议单选框绑定值
  readprivacy:false,//是否以点击隐私协议和使用协议
  readIs1: false,//是否已阅读1
  readIs2: false,//是否已阅读2
})
let timer
const agreementEmit = (value) => {
  console.log('agreementEmit', value)
  if (value == 1) {
    state.readIs1 = true
    if (state.autoPop && !state.readIs2) {
      console.log("autoPop: ", state.autoPop)
      timer = setInterval(() => {
        clearInterval(timer)
        read(0, "隐私协议")
      }, 100)
    }
  } else if (value == 0) {
    state.readIs2 = true
     if (state.autoPop && !state.readIs1) {
      timer = setInterval(() => {
        clearInterval(timer)
        read(1, "使用协议")
      }, 100)
    }
  } 
  if (state.readIs1 == true && state.readIs2 == true ) {
    state.readprivacy = true
    state.autoPop = false
    state.isread = true
  }
}
const readClick=()=>{
  if(state.readprivacy==false){
    handleMessage('请先阅读协议')
  }
}
const read = (type,title) => {
  unref(agreementRef).show(type,title)
}
// 抖动方法
const jitter = () => {
  if (state.isread == true) return
  state.jitter = true
  setTimeout(() => {
    state.jitter = false
  }, 800);
}
const setAutoPop = (isPop) => {
 state.autoPop = isPop 
}
const setEnableClick = () => {
 state.readprivacy = true  
}
defineExpose({
  state, jitter, read, setAutoPop, setEnableClick
});
</script>
<style lang="scss" scoped >
@keyframes shake {

  /* 水平抖动，核心代码 */
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(+2px, 0, 0);
  }

  30%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(+4px, 0, 0);
  }

  50% {
    transform: translate3d(-4px, 0, 0);
  }
}

.read {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;

  span {
    color: rgba(36, 137, 242, 1);
  }
}

.readIsAc {
  color: red !important;
}

.readac {
  animation: shake 800ms ease-in-out;
}

::v-deep .el-checkbox__inner {
  border-radius: 50%;
}
</style>